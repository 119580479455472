.hero-area {
  position: relative;
  height: 100vh;
  background-attachment: fixed; /* Fixed background for the entire section */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-hero-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
}

.hero-slides-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.hero-slides-content h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
  transition: opacity 0.5s ease-in-out;
}

.hero-slides-content .slide-heading {
  animation: fadeInUp 1s ease-in-out forwards;
  /* Animation for fade-in on each slide */
}

/* Keyframe for fade-in effect */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}