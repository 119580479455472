.unit-details-page {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    margin: 10px 120px;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
}

.unit-info {
    margin-bottom: 20px;
}

.unit-info h1 {
    font-size: 4rem;
    margin-bottom: 10px;
    color: #005F73;
    font-weight: bold;
}

.unit-info p {
    font-size: 3rem;
    color: #666;
}

.unit-info p strong {
    color: #00703C;
}

.unit-availability {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
}

.unit-availability.available {
    background-color: #d4edda;
    color: #00703C;
}

.unit-availability.not-available {
    background-color: #f8d7da;
    color: #721c24;
}

.unit-floor {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 2rem;
    margin-right: 10px;
    font-weight: bold;
    background-color: #caf0f8;
    color: #005F73;
}

.unit-condition {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 2rem;
    margin-right: 10px;
    font-weight: bold;
    background-color: #f0cdf2;
    color: #6A0572;
}

.unit-image-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    overflow: hidden;
    align-self: center;
}
.unit-image-container .image-carousel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.image-container {
    width: 40%;
    position: relative;
    cursor: zoom-in;
}

.zoom-container {
    width: 55%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.zoomed-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 300%; 
    display: none; /* Initially hidden */
    transition: background-position 0.1s ease-out;
    background-position: center;
}

.show {
    display: block;
}

.zoom-icon-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px; /* Increased width for the text */
    height: auto; /* Increased height for better text fit */
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-size: 2rem; /* Slightly larger font size */
    font-family: Arial, sans-serif; /* You can change the font style */
    padding: 5px;
}


.unit-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}


.unit-meta {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.meta-item {
    display: flex;
    align-items: center;
    font-size: 2.5rem;
}

.meta-icon {
    margin-right: 1rem;
    color: #005F73;
}

.room-measurements-container {
    margin: 20px 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.toggle-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* To position the hamburger correctly */
    font-size: 2.5rem;
    color: #00703C;
    margin-bottom: 10px;
    cursor: pointer;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 15px;
    position: absolute;
    /* Position it relative to the parent */
    right: 0;
    /* Align it to the right */
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.hamburger span {
    display: block;
    width: 100%;
    height: 2px;
    background: #333;
    transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
    transform: rotate(45deg);
    position: absolute;
    top: 7px;
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
    position: absolute;
    top: 7px;
}


.room-cards {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.room-card {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px 20px;
    width: 240px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.room-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.room-icon {
    font-size: 2.5rem;
    color: #005F73;
    display: flex;
    justify-content: center;
    align-items: center;
}

.room-details {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #555;
}

.room-details span:first-child {
    font-weight: bold;
    color: #00703C;
}

.room-details span:last-child {
    color: #6c757d;
    font-size: 14px;
}


/* Container for all details sections */
.details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Individual detail card */
.details-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.details-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title for each section */
.details-title {
    font-size: 2.5rem;
    color: #00703C;
    margin-bottom: 15px;
    text-align: center;
}

/* Content for each section */
.details-content p {
    font-size: 1.7rem;
    color: #555;
    line-height: 1.6;
}

/* Strong emphasis */
.details-content p strong {
    color: #00703C;
}

/* Button Styles */
.back {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding: 5px 12px;
    color: #005F73;
    background: white;
    border: 2px solid #00703C;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    margin-top: 10px;
    margin-left: 20px;
    
    /* Flexbox to center content (icon + text) */
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.back:hover {
    background: #00703C;
    color: white;
}

/* Icon Styles */
.back-icon {
    margin-right: 5px;
    transition: transform 0.3s ease; /* Add smooth transition for movement */
}

/* On Hover: Move the icon to the left */
.back:hover .back-icon {
    transform: translateX(-5px); /* Moves the icon to the left */
}


@media (max-width: 768px) {
    .unit-details-page {
        margin: 10px 70px;
    }

    .unit-info h1 {
        font-size: 3.5rem;
    }

    .unit-info p {
        font-size: 2.5rem;
    }

    .unit-availability {
        font-size: 1.5rem;
    }

    .unit-floor {
        font-size: 1.5rem;
    }
    .unit-condition {
        font-size: 1.5rem;
    }

    .meta-item {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .unit-details-page {
        margin: 10px 10px;
    }

    .unit-info h1 {
        font-size: 3rem;
    }

    .unit-info p {
        font-size: 1.8rem;
    }

    .meta-item {
        font-size: 1.5rem;
    }

    .toggle-title {
        font-size: 1.7rem;
    }

    .details-title {
        font-size: 1.7rem;
    }

    .details-content p {
        font-size: 1.5rem;
    }

}