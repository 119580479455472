/* General Styles */
.building-details-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

/* Video Section */
.building-video {
    position: relative;
    width: 100%;
    max-width: 1200px;
    /* Adjust size as needed */
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.building-video video {
    width: 100%;
    height: auto;
    display: block;
}

.mute-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    padding: 10px 13px;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.mute-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.mute-button:focus {
    outline: none;
}


/* Building Info */
.building-info {
    padding: 20px;
    text-align: center;
}

.building-info h1 {
    font-size: 5rem;
    text-transform: uppercase;
    color: #00703c;
}

.building-info p {
    font-size: 2.3rem;
    color: #555;
    margin: 15px 120px;
}

/* Image Carousel */
.image-carousel {
    margin: 20px 0;
    width: 100%;
    /* You can adjust the width if needed */
    max-width: 80%;
    /* Set a max-width for the carousel */
    margin: 0 auto;
    /* Center the carousel */
    direction: ltr;
}

.carousel .carousel-slider {
    /* Add padding for carousel items */
    padding: 10px;
}

.carousel-image {
    /* Visible Image size */
    width: 100%;
    max-height: 550px;
    /* Adjust the height for the visible image */
    object-fit: cover;
    /* Ensures the image scales properly */
    border-radius: 8px;
}

.carousel .carousel-item {
    /* Small Images */
    display: flex;
    justify-content: center;
}

html[dir='rtl'] .image-carousel {
    direction: ltr;
    /* Forces the image slider to be LTR even when RTL language is active */
}



/* Info Cards */
.info-cards {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line */
    justify-content: space-between;
    margin: 20px 130px;
    padding: 0 20px;
    gap: 20px; /* Adds space between cards */
}

.card {
    width: 22%;
    background-color: #f5f5f5;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-10px);
}

.location-icon {
    font-size: 3rem;
    color: #005F73;
    margin-bottom: 10px;
}


.floor-icon {
    font-size: 3rem;
    color: #00703C;
    margin-bottom: 10px;
}

.price-icon {
    font-size: 3rem;
    color: #00703C;
    margin-bottom: 10px;
}

.road-icon {
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.61);
    margin-bottom: 10px;
}

.card p {
    font-size: 2rem;
    color: #333;
}

/* Location */
.building-map {
    padding: 20px;
    margin: 20px 130px;
}

.building-map h3 {
    font-size: 4rem;
}
.green-text {
    color: #00703c;
}
.open-maps-button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 2rem;
    color: #fff;
    background-color: #005F73;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.open-maps-button:hover {
    background-color: #015262;
}


/* Units by Floors */
.units-by-floor {
    padding: 20px;
    margin: 10px 130px;
}
.units-by-floor h3 {
    font-size: 4rem;
}

.floor-section {
    margin-bottom: 30px;
}

.floor-section h3 {
    font-size: 2rem;
    color: #005F73;
    margin-bottom: 10px;
}

/* Styling for the floor units container */
.floor-units {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

/* Styling for each unit card */
.unit-card {
    position: relative;
    background: #f7f9fc;
    margin: 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(62, 28, 131, 0.1);
    width: 300px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s ease;
}

.unit-card:hover {
    transform: translateY(-10px);
}

/* Styling for the unit title */
.unit-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.unit-card .unit-img {
    width: 100%;
    height: auto;
    max-width: 200px; /* Adjust as needed */
    border-radius: 10px;
    transition: 0.5s linear;
    cursor: pointer;
  }
  
  .unit-card .unit-img:hover {
    transform: scale(1.2);
  }

  .unit-card .unit-state {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f8d7da;
    color: #721c24;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
  }
  
  .unit-card .unit-state.available {
    background-color: #d4edda;
    color: #00703C;
  }

/* Styling for the meta-data section */
.unit-meta-data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* Styling for each meta item (icon + number) */
.meta-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Styling for the icons */
.icon {
    font-size: 18px;
    color: #00703C;
    margin-right: 2px;
    margin-left: 10px;
}

/* Styling for the meta numbers */
.meta-number {
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

hr {
    border-top: 2px solid #ddd;
    margin-top: 20px;
}
/* Media Queries for Smaller Screens */
@media (max-width: 768px) {

    .building-info p {
        margin: 15px 70px;
    }

    .carousel-image {
        max-height: 300px;
    }

    .info-cards {
        margin: 20px 70px;
    }

    .location-icon,
    .floor-icon,
    .price-icon,
    .road-icon {
        font-size: 3rem;
    }
    
    .card p {
        font-size: 1.5rem;
    }

    .open-maps-button {
        font-size: 1.5rem;
    }

    .card {
        width: 48%;
    }

    .building-map {
        margin: 10px 70px;
    }

    .units-by-floor {
        margin: 10px 70px;
    }
}
@media (max-width: 480px) {
    .building-info p {
        font-size: 1.8rem;
        margin: 15px 20px;
    }

    .carousel-image {
        max-height: 170px;
    }

    .info-cards {
        margin: 20px 20px;
    }

    .location-icon,
    .floor-icon,
    .price-icon,
    .road-icon {
        font-size: 2.5rem;
    }
    
    .card p {
        font-size: 1.3rem;
    }

    .open-maps-button {
        font-size: 1.3rem;
    }

    .card {
        width: 100%;
    }

    .building-map {
        margin: 10px 20px;
    }

    .units-by-floor {
        margin: 10px 20px;
    }
}