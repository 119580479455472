.coming-soon-section {
  position: relative;
  background-image: url('../../public/img/cta.jpg'); /* Add your fixed background image */
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  padding: 100px 20px;
  height: 400px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  overflow: hidden; /* Prevent any content overflow */
}

.coming-soon-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for full section */
  z-index: 1; /* Ensure it appears below content */
}

/* Fallback for iPhones and smaller screens */
@media screen and (max-width: 430px) {
  .coming-soon-section {
    background-attachment: scroll; 
    background-size: cover; 
    background-position: center;
  }
}

.coming-soon-section .content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  text-align: center;
}

.coming-soon-section h2 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #fff;
}

.coming-soon-section p {
  font-size: 1.5rem;
  margin: 0;
}
