@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);

* {
  font-family: Roboto, sans-serif;
}

.footer-distributed {
  /* background: linear-gradient(to right, #005F73 0%, #005F73 100%); */
  background-image: url("../../public/bg-pat.png");
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 20px;
  margin-top: auto;
  width: 100%;
  color: #fff;
}

.footer-left {
  display: flex;
  align-items: center;
  gap: 40vh; /* Space between logo, name, email, and phone */
}
.footer-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer-left a {
  color: #fff; /* Keep the text color white */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Optional: Smooth transition for hover */
}

.footer-left a:hover {
  color: #ddd; /* Keep the color white on hover */
  text-decoration: none; /* Ensure no underline appears */
}

.logo-foot {
  height: 50px;
  width: auto;
  object-fit: contain;
}

.logo-text-foot {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #333;
  background: linear-gradient(90deg, white, white);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.email, .phone {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  width: 100%;
}

.footer-center {
  display: flex;
  flex-direction: column; /* Default stacking for smaller screens */
  align-items: flex-start;
  gap: 10px;
}

.footer-contact p {
  margin: 8px 0; /* Add vertical spacing between paragraphs */
  font-size: 14px;
  display: flex; /* Use flex to align the icon and text */
  align-items: center; /* Vertically align the icon and text */
}

.footer-contact i {
  margin-right: 10px; /* Add spacing between the icon and the text */
  color: #bbf9dc; /* Ensure icon color matches text */
  font-size: 18px; /* Adjust icon size to fit */
}
.footer-hours {
  display: grid;
  grid-template-columns: auto auto; /* Side-by-side alignment for larger screens */
  gap: 5px; /* Spacing between day and hours */
}

.footer-hours p {
  margin: 0;
  font-size: 14px;
}
.footer-hours .first {
  font-weight: bold;
  color: #bbf9dc;
}


.footer-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer-right a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 18px;
  color: #265549;
  transition: all 0.3s ease;
}

.footer-right a:hover {
  transform: scale(1.1);
  background-color: rgb(21, 107, 212);
  color: #fff;
}

#insta:hover {
  background-color: rgb(212, 21, 56);
}
#tiktok:hover {
  background-color: rgb(0, 0, 0);
}

/* Responsive Design */
@media (max-width: 780px) {

  .footer-left,
  .footer-right,
  .footer-center{
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }

  .footer-distributed {
    flex-direction: row; /* Stack items vertically */
    align-items: center;
    text-align: center;
  }

  .footer-left {
    width: 50%; /* Keep full width */
    display: flex;
    justify-content: center;
  }

  .footer-center {
    width: 75%; /* 3/4 of remaining space */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-right {
    width: 25%; /* 1/4 of remaining space */
    display: flex;
    flex-direction: row; /* Stack icons */
    align-items: center;
  }

  .footer-right a {
    width: 35px;
    height: 35px;
    font-size: 16px;
    margin-bottom: 8px; /* Space between stacked icons */
  }
}

@media (max-width: 480px) {
  .footer-distributed {
    flex-direction: row; /* Stack items vertically */
    align-items: center;
    text-align: center;
  }

  .footer-left {
    width: 50%; /* Keep full width */
    display: flex;
    justify-content: center;
  }

  .footer-center {
    width: 75%; /* 3/4 of remaining space */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-right {
    width: 25%; /* 1/4 of remaining space */
    display: flex;
    flex-direction: column; /* Stack icons */
    align-items: center;
  }

  .footer-right a {
    width: 35px;
    height: 35px;
    font-size: 16px;
    margin-bottom: 8px; /* Space between stacked icons */
  }
  .logo-foot {
    height: 40px;
  }

  .logo-text-foot {
    font-size: 24px;
  }

  .email, .phone {
    font-size: 14px;
  }

  /* .footer-hours {
    margin-left: 10px;
    grid-template-columns: 1fr; 
  } */
}

