/* Container for the building list */
.building-list-container {
    width: 100%;
    padding: 40px 20px;
    text-align: center;
}

.building-list-header h2 {
    font-size: 48px;
    line-height: 1.2;
}

.green-text {
    color: #00703c;
}

.sub-title {
    color: gray;
    font-size: 18px;
    margin-top: 15px;
}


.building-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Three cards per row */
    gap: 20px;
    margin-top: 30px;
}

/* Building card style */
.building-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.building-card:hover {
    transform: scale(1.05);
    /* Slight zoom effect on hover */
}

/* Building card inner container */
.building-card-inner {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.image-slider {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    direction: ltr;
}

.building-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: opacity 0.5s ease;
}

html[dir='rtl'] .image-slider {
    direction: ltr;
    /* Forces the image slider to be LTR even when RTL language is active */
}

/* Building details section */
.building-details {
    margin-top: 20px;
}

/* Building name alignment */
.building-details .building-name {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #005F73;
    text-align: left;
}

html[dir='rtl'] .building-name {
    text-align: right;
}

/* Price range alignment */
.price-range p {
    font-size: 2rem;
    font-weight: bold;
    color: #00703C;
    margin-bottom: 15px;
    text-align: left;
}

html[dir='rtl'] .price-range p {
    text-align: right;
}

.location {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 20px;
}

.location svg {
    margin-right: 10px;
    color: #C8102E;
    font-size: 1.5rem;
}

/* Reverse margin for RTL */
html[dir='rtl'] .location svg {
    margin-left: 10px;
    margin-right: 0;
}

/* Description alignment */
.building-details .description {
    font-size: 1.5rem;
    color: #555;
    text-align: left;
}

html[dir='rtl'] .building-details .description {
    text-align: right;
}

.building-card-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}


/* Responsive Design for tablets and smaller screens */
@media (max-width: 768px) {
    .building-list {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        /* Adjust grid for smaller screens */
    }

    .building-card-inner {
        padding: 15px;
    }

    .building-image {
        height: 200px;
    }

    .building-name {
        font-size: 2rem;
    }

    .location {
        font-size: 1.5rem;
    }

    .price-range p {
        font-size: 2rem;
    }

    .building-details .description {
        font-size: 1.3rem;
    }
}

/* Mobile responsiveness */
@media (max-width: 480px) {
    .building-list {
        grid-template-columns: 1fr;
        /* Stack cards on small screens */
    }

    .building-card-inner {
        padding: 10px;
    }

    .building-image {
        height: 180px;
    }

    .building-name {
        font-size: 1.8rem;
    }

    .location {
        font-size: 1.2rem;
    }

    .price-range p {
        font-size: 1.5rem;
    }

    .building-details .description {
        font-size: 1rem;
    }
}