.contact-us {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  padding: 50px;
  flex-wrap: wrap;
  gap: 20px;
}

.hero-text {
  flex: 1;
  max-width: 500px;
}

.hero-text h3 {
  font-size: 48px;
  line-height: 1.2;
}

.green-text {
  color: #00703c;
}

.fade-text {
  color: gray;
  font-size: 18px;
  margin-top: 15px;
}

.hero-image-wrapper {
  position: relative;
  flex: 1;
}

.hero-image {
  max-width: 100%;
  border-radius: 10px;
}

.call-us {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.call-us:hover {
  background: rgba(0, 0, 0, 0.8);
}

.call-us a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.call-us a:hover {
  text-decoration: underline;
}


/* Contact Content */
.contact-content {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  gap: 20px;
  flex-wrap: wrap;
  background: #f9f9f9;
  border-radius: 10px;
}

.contact-form{
  flex: 1;
  min-width: 300px;
  background: white;
  padding: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.contact-info {
  flex: 1;
  min-width: 300px;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form button {
  background-color: #00703c;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #005f73;
}

.contact-info h3 {
  font-size: 32px;
  color: #00703c;
  margin-bottom: 10px;
}

.contact-info p,
.contact-info ul {
  color: gray;
  font-size: 18px;
  margin-top: 15px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
}

.contact-info ul li {
  margin-bottom: 10px;
  font-size: 18px;
  color: black;
}
.open-maps-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #00703c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.open-maps-button:hover {
  background-color: #005f32;
}


.office-location,
.office-hours {
  margin-bottom: 20px;
}

.office-location p,
.office-hours li {
  font-size: 18px;
  color: #005F73;
  font-weight: bold;
}
.office-location p{
  font-style: italic;
  color: gray;
}
.office-hours .timeopen {
  color: #005F73;
}
.office-hours .timeclose {
  color: #C8102E;
}
/* Add this to your Contact.css or global stylesheet */

.contact-us .rtl {
  direction: rtl;
  text-align: right; 
}

.contact-us  .rtl .contact-form input,
.contact-us  .rtl .contact-form textarea,
.contact-us  .rtl .contact-info p,
.contact-us  .rtl .contact-info h2 {
  text-align: right; 
}

/* Optionally adjust margins, paddings, and layout if needed */
.contact-us  .rtl .contact-form,
.contact-us  .rtl .contact-info {
  margin-right: 20px;
}

.contact-us  .rtl .contact-info {
  margin-left: 0; /* Ensure the contact info box moves to the right */
}

.contact-us  .rtl .office-location iframe {
  margin-right: 0; /* Fix any issues with map iframe placement */
}


/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    order: -1;
  }

  .hero-text h3 {
    font-size: 32px;
  }

  .fade-text {
    margin-top: 10px;
  }

  .hero-image-wrapper {
    position: relative;
  }

  .call-us {
    position: absolute;
    bottom: 10px;
    left: 35%;
    transform: translateX(-50%);
    font-size: 16px;
  }

  .contact-content {
    flex-direction: column;
    padding: 20px;
  }

  .contact-form {
    margin-bottom: 30px;
  }
  .contact-info {
    padding: 20px;
    font-size: 18px;
  }

  .contact-info h2 {
    font-size: 32px;
  }

  .contact-info p,
  .contact-info ul {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .hero-text h3 {
    font-size: 28px;
  }

  .fade-text {
    font-size: 16px;
  }

  .call-us {
    font-size: 14px;
    left: 20%;
  }

  .contact-form h2 {
    font-size: 28px;
  }

  .contact-info h2 {
    font-size: 28px;
  }

  .contact-info ul li {
    font-size: 16px;
  }

  .contact-form button {
    font-size: 16px;
  }
}