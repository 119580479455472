
.title {
  display: flex;
  align-items: center;
  /* Vertically center the image and text */
  gap: 10px;
  /* Space between the image and the text */
  font-family: 'Montserrat', sans-serif;
  /* Use a clean and modern font */
}

.logo {
  height: 50px;
  /* Adjust logo size */
  width: auto;
  /* Maintain aspect ratio */
  object-fit: contain;
}
.nav-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #005F73; /* Default text color */
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  display: inline-block; /* Keeps the hover effect within the text bounds */
  transition: color 0.3s ease; /* Smooth color change */
}

/* Dot below text */
.nav-text::after {
  content: "";
  position: absolute;
  left: 50%; /* Center the dot */
  bottom: -8px; /* Position the dot just below the text */
  width: 8px; /* Initial size of the dot */
  height: 8px; /* Initial size of the dot */
  background-color: #00703C; /* Green color */
  border-radius: 50%; /* Make it circular */
  transform: translateX(-50%); /* Adjust position to ensure it's centered */
  opacity: 0; /* Initially hide the dot */
  transition: all 0.3s ease; /* Smooth transition for size and opacity */
}

/* Hover effect */
.nav-text:hover {
  color: #FFFFFF; /* Change text color to white on hover */
}

/* Hover effect on the dot */
.nav-text:hover::after {
  opacity: 1; /* Make the dot visible */
  transform: translateX(-50%) scale(1.2); /* Grow the dot slightly */
  animation: pop 0.3s ease forwards; /* Pop animation */
}

/* Pop animation */
@keyframes pop {
  0% {
    transform: translateX(-50%) scale(1.2); /* Start from a larger size */
  }
  50% {
    transform: translateX(-50%) scale(1.5); /* Grow a bit larger */
  }
  100% {
    transform: translateX(-50%) scale(1); /* Return to original size */
  }
}

/* Active state */
.nav-text:active {
  color: #203C88; /* Slightly darker color on click */
  transition: color 0.1s ease;
}

.logo-text {
  font-family: "Montserrat", sans-serif;
  font-size: 36px; /* Adjust text size */
  font-weight: bold; /* Make the text bold */
  letter-spacing: 2px; /* Add spacing between letters */
  display: flex;
  color: #333; /* Neutral dark gray for contrast on white */
  background: linear-gradient(90deg, #00703C, #005F73); /* Gradient for a vibrant logo */
  background-clip: text; /* Clips the gradient to text */
  -webkit-text-fill-color: transparent; /* Makes text transparent to show gradient */
}

/* Position the language switcher */
.language-switcher {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 5px 12px;
  color: #005F73;
  /* Match the text color of the other fields */
  background: white;
  /* White background to match the other fields */
  border: 2px solid #00703C;
  /* Green border for accent */
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  /* Fix it to the top-right corner of the screen */
  top: 22px;
  /* Adjust vertical positioning */
  right: 100px;
  /* Adjust horizontal positioning */
  z-index: 1050;
  /* Ensure it is on top of other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.language-switcher:hover {
  background: #00703C;
  /* Green background on hover */
  color: white;
  /* White text on hover */
}

/* Adjust navbar to avoid overlap with language switcher */
.navbar .container {
  padding-right: 80px; /* Adjust based on language switcher's size */
  position: relative;
}

.text-right {
  justify-content: flex-end !important; /* Align to the right when Arabic is selected */
}

/* Handle RTL layout */
.rtl .navbar-nav {
  direction: rtl; /* Right-to-left direction */
}

.rtl .navbar-nav li {
  text-align: right; /* Align text to the right */
}


@media (max-width: 1400px) {
  .language-switcher {
    right: 50px;
  }
}

@media (max-width: 1300px) {
  .language-switcher {
    right: 20px;
  }
}

/* Disable animation and dot when screen is small (e.g., mobile menu) */
@media (max-width: 768px) {
  /* If the toggle button is present or the screen is smaller, disable the animation */
  .nav-text::after {
    display: none; /* Remove the dot entirely on smaller screens */
  }
  
  .nav-text:hover::after {
    opacity: 0; /* Do not show the dot on hover */
  }

  /* Optionally, you can remove the hover color effect as well */
  .nav-text:hover {
    color: #005F73; /* Keep the default color or apply any other style */
  }
  .language-switcher {
    right: 15px;
  }
}
