.faq-section {
    padding: 50px;
    background-color: white;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.faq-title {
    text-align: center;
    margin-bottom: 30px;
}

.faq-title h1 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
}

.faq-title .green-text {
    color: #00703c; /* Green text */
}

.faq-container {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: hidden;
    border-radius: 30px;
}

.faq-item {
    position: relative;
    border-bottom: 1px solid #ddd;
    padding: 20px;
}

.faq-item:last-child {
    border-bottom: none;
}

.faq-question {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #005F73;
    background: white;
    transition: background 0.3s;
    padding: 10px 20px;
    border-radius: 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.faq-question .question {
    flex-grow: 1; /* Allows the question text to take up available space */
    margin-right: 20px; /* Creates space for the hamburger icon */
    padding-right: 20px; /* Adjust this if needed */
}

.faq-question:hover {
    background: #eef4f6;
}


.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 18px;
    position: absolute; /* Position it relative to the parent */
    right: 20px; /* Adjust this value to add spacing from the right */
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.hamburger span {
    display: block;
    width: 100%;
    height: 2px;
    background: #333;
    transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
    transform: rotate(45deg);
    position: absolute;
    top: 7px;
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
    position: absolute;
    top: 7px;
}

.faq-answer {
    padding: 15px 20px;
    font-size: 16px;
    line-height: 1.6;
    background: #fff;
}

.active .faq-answer {
    animation: slideDown 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .faq-title h1 {
        font-size: 28px;
    }

    .faq-container {
        padding: 15px;
    }

    .faq-question {
        font-size: 16px;
        padding: 10px;
    }

    .faq-answer {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .faq-title h1 {
        font-size: 24px;
    }

    .faq-container {
        padding: 10px;
    }

    .faq-question {
        font-size: 14px;
        padding: 8px;
    }

    .faq-answer {
        font-size: 12px;
    }
}

/* Slide Down Animation */
@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-10px) scale(0.98);
    }
    50% {
        opacity: 0.5;
        transform: translateY(0) scale(1.02);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}
