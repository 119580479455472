html, body {
  height: 100%; /* Ensure full height */
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use dynamic viewport height */
}


.content-container {
  flex: 1; /* Allow content to take available space */
  margin-top: 80px; /* Account for fixed Navbar height */
}



