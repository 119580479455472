.about-us {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    padding: 20px;
}

/* Hero Section */
.about-hero-section {
    display: flex;
    align-items: center;
    padding: 50px;
    flex-wrap: wrap;
    gap: 20px;
}

.about-hero-text {
    flex: 1;
    max-width: 500px;
}

.about-hero-text h3 {
    font-size: 48px;
    line-height: 1.2;
}

.green-text {
    color: #00703c;
}

.whitegreen-text {
    color: #02ab5c;
}

.fade-text {
    color: gray;
    font-size: 18px;
    margin-top: 15px;
}

.about-hero-image-wrapper {
    flex: 1;
}

.about-hero-image {
    max-width: 100%;
    border-radius: 10px;
}

/* About Content */
.about-content {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    gap: 20px;
    flex-wrap: wrap;
    background: #f9f9f9;
    border-radius: 10px;
}

.our-story,
.our-mission {
    flex: 1;
    min-width: 300px;
    background: white;
    padding: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.our-story h2,
.our-mission h2 {
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.our-story p,
.our-mission p {
    color: gray;
    font-size: 18px;
    margin-top: 15px;
    line-height: 1.5;
}

.get-in-touch-section {
    position: relative;
    height: 50vh;
    background-image: url("../../public/img/nature.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    text-align: left;
    padding: 50px;
}

/* .get-in-touch-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background: url("../../public/img/nature.png") no-repeat center center/cover;
    color: #fff;
    text-align: left;
    position: relative;
    overflow: hidden;
} */

.get-in-touch-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Adds a dark overlay */
    z-index: 1;
}

/* Fallback for iPhones and smaller screens */
@media screen and (max-width: 430px) {
    .get-in-touch-section {
      background-attachment: scroll; 
      background-size: cover; 
      background-position: center;
    }
  }

.text-content {
    z-index: 2;
    max-width: 600px;
}

.text-content h2 {
    font-size: 48px;
    margin-bottom: 20px;
    color: white;
    /* Highlights the header */
}

.text-content p {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.contact-button {
    z-index: 2;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: #00703c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    /* Remove underline */
    display: inline-block;
    /* Ensures padding is respected */
    text-align: center;
    /* Center text */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, transform 0.3s;
}

.contact-button:hover {
    background: #005f73;
}

.contact-button:active {
    transform: scale(0.95);
}


.about-content .rtl,
.get-in-touch-section .rtl {
    direction: rtl;
    text-align: right;
}

.get-in-touch-section .rtl h2,
.get-in-touch-section .rtl p,
.about-content .rtl h2,
.about-content .rtl p {
    text-align: right ;
    /* Ensures heading and paragraph text align properly */
}


@media (max-width: 768px) {
    .about-hero-section {
        flex-direction: column;
        text-align: center;
    }

    .about-hero-text {
        order: -1;
    }

    .about-hero-text h3 {
        font-size: 32px;
    }

    .fade-text {
        margin-top: 10px;
    }

    .about-content {
        flex-direction: column;
        padding: 20px;
    }

    .our-story,
    .our-mission {
        margin-bottom: 30px;
    }

    .get-in-touch-section {
        flex-direction: column;
        text-align: center;
    }

    .text-content {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .about-hero-text h3 {
        font-size: 28px;
    }

    .fade-text {
        font-size: 16px;
    }

    .our-story h2,
    .our-mission h2 {
        font-size: 28px;
    }

    .our-story p,
    .our-mission p {
        font-size: 16px;
    }

    .text-content h2 {
        font-size: 28px;
    }

    .text-content p {
        font-size: 14px;
    }

    .contact-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}